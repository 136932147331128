import { createSlice } from "@reduxjs/toolkit";
import {
  getServiceHoursByYear4Years,
  getServiceHoursForState4Years,
  getServiceHoursByMonths,
  getServiceHoursForCustomerByMachine4Years,
  getTotalServiceHoursForCustomerInStateByYear,
  getServiceHoursAllStatesLast4Years,
  sendWeeklyServiceReports,
  sendWarrantyCallsDetails,
  sendMachineList,
} from "./actions";

const initialState = {
  loading: false,
  serviceHoursByYear4Years: {
    loading: false,
    data: [],
    error: null,
  },
  serviceHoursByMonths: {
    loading: false,
    data: [],
    error: null,
  },
  serviceHoursForCustomerByMachine4Years: [],
  serviceHoursByCustomerByYear: {
    loading: false,
    data: [],
    error: null,
  },
  serviceHoursForState4Years: {
    loading: false,
    data: [],
    error: null,
  },
  serviceHoursAllStatesLast4Years: {
    loading: false,
    data: [],
    error: null,
  },
  error: null,
  success: false,
};

const authSlice = createSlice({
  name: "dashboard",
  initialState,
  reducers: {},
  extraReducers: {
    [sendWarrantyCallsDetails.pending]: (state) => {
      state.loading = true;
    },
    [sendWarrantyCallsDetails.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [sendWarrantyCallsDetails.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    [sendWeeklyServiceReports.pending]: (state) => {
      state.loading = true;
    },
    [sendWeeklyServiceReports.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [sendWeeklyServiceReports.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    [sendMachineList.pending]: (state) => {
      state.loading = true;
    },
    [sendMachineList.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [sendMachineList.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    [getServiceHoursByYear4Years.pending]: (state) => {
      state.loading = true;
      state.serviceHoursByYear4Years.loading = true;
      state.serviceHoursByYear4Years.error = null;
    },
    [getServiceHoursByYear4Years.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.serviceHoursByYear4Years.loading = false;
      state.serviceHoursByYear4Years.data = payload;
    },
    [getServiceHoursByYear4Years.rejected]: (state, { payload }) => {
      state.loading = false;
      state.serviceHoursByYear4Years.loading = false;
      state.serviceHoursByYear4Years.error = payload;
    },
    [getServiceHoursByMonths.pending]: (state) => {
      state.loading = true;
      state.serviceHoursByMonths.loading = true;
      state.serviceHoursByMonths.error = null;
    },
    [getServiceHoursByMonths.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.serviceHoursByMonths.loading = false;
      state.serviceHoursByMonths.data = payload;
    },
    [getServiceHoursByMonths.rejected]: (state, { payload }) => {
      state.loading = false;
      state.serviceHoursByMonths.loading = false;
      state.serviceHoursByMonths.error = payload;
    },
    [getServiceHoursForCustomerByMachine4Years.pending]: (state) => {
      state.loading = true;
      state.error = null;
    },
    [getServiceHoursForCustomerByMachine4Years.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.serviceHoursForCustomerByMachine4Years = payload;
    },
    [getServiceHoursForCustomerByMachine4Years.rejected]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [getTotalServiceHoursForCustomerInStateByYear.pending]: (state) => {
      state.loading = true;
      state.serviceHoursByCustomerByYear.loading = true;
      state.serviceHoursByCustomerByYear.error = null;
    },
    [getTotalServiceHoursForCustomerInStateByYear.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.serviceHoursByCustomerByYear.loading = false;
      state.serviceHoursByCustomerByYear.data = payload;
    },
    [getTotalServiceHoursForCustomerInStateByYear.rejected]: (state, { payload }) => {
      state.loading = false;
      state.serviceHoursByCustomerByYear.loading = false;
      state.serviceHoursByCustomerByYear.error = payload;
    },
    [getServiceHoursForState4Years.pending]: (state) => {
      state.loading = true;
      state.serviceHoursForState4Years.loading = true;
      state.serviceHoursForState4Years.error = null;
    },
    [getServiceHoursForState4Years.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.serviceHoursForState4Years.loading = false;
      state.serviceHoursForState4Years.data = payload;
    },
    [getServiceHoursForState4Years.rejected]: (state, { payload }) => {
      state.loading = false;
      state.serviceHoursForState4Years.loading = false;
      state.serviceHoursForState4Years.error = payload;
    },
    [getServiceHoursAllStatesLast4Years.pending]: (state) => {
      state.loading = true;
      state.serviceHoursAllStatesLast4Years.loading = true;
      state.serviceHoursAllStatesLast4Years.error = null;
    },
    [getServiceHoursAllStatesLast4Years.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.serviceHoursAllStatesLast4Years.loading = false;
      state.serviceHoursAllStatesLast4Years.data = payload;
    },
    [getServiceHoursAllStatesLast4Years.rejected]: (state, { payload }) => {
      state.loading = false;
      state.serviceHoursAllStatesLast4Years.loading = false;
      state.serviceHoursAllStatesLast4Years.error = payload;
    },
  },
});

export default authSlice.reducer;
