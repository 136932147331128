import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, useTheme, Card, ListItemButton, Stack, Typography } from "@mui/material";
import {
  sendWeeklyServiceReports,
  sendInstallationReport,
  sendWarrantyCallsDetails,
  sendMachineList,
} from "../../features/dashboard/actions";
import { ChevronRight as RightArrowIcon, Send as SendIcon } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";

const Reports = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const { loading } = useSelector((state) => state.dashboard);

  const [selectedItem, setSelectedItem] = useState("Weekly report");
  const tabItems = ["Weekly report", "Monthly installation report", "Warranty calls details", "Machine List"];

  return (
    <Stack sx={{ p: 5, flex: 1 }} direction={"row"} columnGap={2}>
      <Stack sx={{ flex: 0.25 }}>
        {tabItems.map((tabItem, index) => (
          <Stack alignItems={"flex-start"} direction={"row"} sx={{ mb: 1 }} key={index}>
            <Card
              sx={{
                minWidth: 270,
                py: 1,
                cursor: "pointer",
                backgroundColor: selectedItem === tabItem ? "#DAA520" : theme.palette.primary.main,
              }}
            >
              <ListItemButton onClick={() => setSelectedItem(tabItem)} sx={{ justifyContent: "space-between" }}>
                <Typography color={"#fff"}>{tabItem}</Typography>
                <RightArrowIcon sx={{ color: "#fff" }} />
              </ListItemButton>
            </Card>
          </Stack>
        ))}
      </Stack>
      <Card sx={{ pt: 3, px: 3, flex: 0.75, mb: 1 }}>
        {selectedItem === "Weekly report" && (
          <Stack alignItems={"center"}>
            <Typography variant="h6">Visited Customers and Weekly Service Report for week ending</Typography>
            <Button
              sx={{ mt: 3, minWidth: 250 }}
              variant="contained"
              onClick={() => {
                dispatch(sendWeeklyServiceReports());
                toast.success(`Weekly report sent successfully.`);
              }}
              disabled={loading}
              endIcon={<SendIcon />}
            >
              Email {selectedItem}
            </Button>
          </Stack>
        )}
        {selectedItem === "Monthly installation report" && (
          <Stack alignItems={"center"}>
            <Typography variant="h6" mb={2}>
              Installation Report for Last [30, 90, 180, 365] days
            </Typography>
            {[30, 90, 180, 365].map((noOfDays, index) => (
              <Stack key={index}>
                <Button
                  variant="contained"
                  sx={{ mb: 1, minWidth: 250 }}
                  onClick={() => {
                    dispatch(sendInstallationReport(noOfDays));
                    toast.success(`Monthly Installation Report sent successfully.`);
                  }}
                  disabled={loading}
                  endIcon={<SendIcon />}
                >
                  {` Email ${noOfDays} days reports `}
                </Button>
              </Stack>
            ))}
          </Stack>
        )}
        {selectedItem === "Warranty calls details" && (
          <Stack alignItems={"center"}>
            <Typography variant="h6" mb={2}>
              Warranty Calls Details for Last [30, 90, 180, 365] days
            </Typography>
            {[30, 90, 180, 365].map((noOfDays, index) => (
              <Stack key={index}>
                <Button
                  variant="contained"
                  sx={{ mb: 1, minWidth: 250 }}
                  onClick={() => {
                    dispatch(sendWarrantyCallsDetails(noOfDays));
                    toast.success(`Warranty Calls Details sent successfully.`);
                  }}
                  disabled={loading}
                  endIcon={<SendIcon />}
                >
                  {` Email ${noOfDays} days reports `}
                </Button>
              </Stack>
            ))}
          </Stack>
        )}
        {selectedItem === "Machine List" && (
          <Stack alignItems={"center"}>
            <Typography variant="h6">Machine List</Typography>
            <Button
              sx={{ mt: 3, minWidth: 250 }}
              variant="contained"
              onClick={() => {
                dispatch(sendMachineList())
                  .then(() => toast.success(`Machine List sent successfully.`))
                  .catch((error) => toast.error(`Error sending Machine List: ${error.message}`));
              }}
              disabled={loading}
              endIcon={<SendIcon />}
            >
              Email {selectedItem}
            </Button>
          </Stack>
        )}
      </Card>
      <ToastContainer />
    </Stack>
  );
};

export default Reports;
